html {
	scroll-behavior: smooth;
}

body {
  background-color: #040914;

  background-image: url("background_bottom.png"), url("background_top.png");                 
  background-size: 60%, 100%; 
  background-position:  bottom right, top;

  background-image: url("background_top.png"), url("background_bottom.png");                 
  background-size:  100%, 80%; 
  background-position:  top right, bottom right;


  background-repeat: no-repeat, no-repeat;
  /* padding-left: 1%;
  padding-right: 1%; */
  
}

.App {
  text-align: center;
  
}


.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  /* color: white; */
}

.App-link {
  color: #61dafb;
}

/* @keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

.appbar {
  z-index: 100;
}

.page_container {
  z-index: 50;
  margin-left: auto;
  margin-right: auto;
  /* min-width: 900px;  */
  /* max-width: 1200px; */
  /* max-width: 900px; */
  padding-top: 60px;
}

.card {
  margin-top: 30px;
}

.cardText br {
  display:block; 
  margin-top:10px; 
  line-height:22px; 
  content: " ";
}

.nav-item > .active {
  border-bottom: 1px solid #333;
  
}

.barLink {
  /* color:#08538a; */
  cursor: pointer;
  font-size: 1.3em;
  /* font-family: 'Something Fishy_Font'; */
  font-family: Sans-Serif;
  font-weight: bolder;
  margin-right: 30px;
  /* text-decoration: underline; */
  white-space: nowrap;
  word-spacing: -3px;
}

.barLink:hover {
  color: #81caff;
}

@font-face {
  font-family: 'Something Fishy_Font';
  src: url("fonts/Something Fishy_Font.woff2") format('woff2');
}

.cardText {
  font-weight: 500;
  font-size: 1.7rem;
}

.small_link{
  font-size: 20px;
}

.offerHeading{
  margin-bottom:20px; 
  font-family:'Something Fishy_Font';
  font-size: 3rem;
  font-weight: normal;
  text-transform: uppercase;
  margin-top: 5px;
  color:#08538a;
  text-align: center;
}
.mvp {
  text-align:center; 
  background-image: url("../public/mvp.png"); 
  background-repeat: no-repeat; 
  background-position-x: calc(30% + 400px); 
  background-size: 600px 100%; 
  height:600px
}


#slideshow {
  text-align: center;
  overflow: hidden;
  height: 510px;
  width: 90%;
  margin: 0 auto;
}



.slide-wrapper {
  width: calc(100% * 4);
  animation: 20s ease-out slide infinite;
}

.slide {
  float: left;
  height: 510px;
  width: calc(100% / 4);
  background-repeat: no-repeat; 
  background-size: 100% ; 
}

.slide:nth-child(1) {
  background-image: url("../public/mvp1.png");  
}

.slide:nth-child(2) {
  background-image: url("../public/mvp2.png"); 
}

.slide:nth-child(3) {
  background-image: url("../public/mvp3.png"); 
}

.slide:nth-child(4) {
  background-image: url("../public/mvp4.png"); 
}

.slide-number {
  color: #000;
  text-align: center;
  font-size: 10em;
}

@keyframes slide {
  20% {margin-left: 0px;}
  30% {margin-left: calc(-1 * 100%);}
  50% {margin-left: calc(-1 * 100%);}
  60% {margin-left: calc(-2 * 100%);}
  70% {margin-left: calc(-2 * 100%);}
  80% {margin-left: calc(-3 * 100%)}
  90% {margin-left: calc(-3 * 100%)}
}
